/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  Form, Input, Button, Card, Row, Col,
  Upload
} from 'antd'
// import { Selector } from 'antd-mobile'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import settings from '../../config'
import { getDeviceData } from '../../utils/helpers'
// import ReactGA from 'react-ga4'

const { Dragger } = Upload;

const formItemLayout = {
  labelCol: {
    xs: { span: 6, offset: 0 },
    md: { span: 11, offset: 2 },
  },
  wrapperCol: {
    xs: 24,
    md: 13,
  }
}

const formItemLayoutWithOutLabel = {
  // wrapperCol: {
  //   xs: { span: 24, offset: 0 },
  //   sm: { span: 20, offset: 4 }
  // }
}

const { TextArea } = Input

class CertificateUploadForm extends React.Component {
  state = {
    rating: '',
    uploadStatuses: {},
    submitting: false
  }

  handleSubmit = (values) => {
    this.setState({ submitting: true })

    this.props.submitRequest({ message: values.message }, () => this.setState({ submitting: false }))

    // ReactGA.event({ category: 'Generate Inspiration', action: 'Inspiration Generated' })
  }

  render () {
    const { isMobile, loading } = this.props
    const { submitting } = this.state

    const headers = {}
    headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

    // const popconfirmProps = {
    //   okText: 'Tak',
    //   cancelText: 'Anuluj',
    //   placement: 'bottom',
    //   cancelButtonProps: isMobile ? { size: 'large' } : undefined,
    //   okButtonProps: isMobile ? { size: 'large' } : undefined
    // }
    //
    // const popconfirmItemDeleteProps = {
    //   ...popconfirmProps,
    //   placement: 'left'
    // }

    return (
      <>
        <Form
          onFinish={this.handleSubmit}
          {...formItemLayoutWithOutLabel}
          layout='horizontal'
          className={'inspiration' + (isMobile ? ' inspiration-mobile' : '')}
          ref={this.props.formRef}
          scrollToFirstError={{ behavior: 'smooth' }}
          labelWrap
        >
          <Form.Item
            name='message'
            // label='Opcjonalnie dodaj kilka słów o swojej pracy'
            validateTrigger='onBlur'
            // {...formItemLayout}
          >
            <TextArea disabled={loading} placeholder='Opcjonalnie dodaj kilka słów o swojej pracy' />
          </Form.Item>

          <Form.List
            name='used_ingredients'
            rules={[
              {
                validator: async (_, names) => {
                  if (!names?.length) {
                    return Promise.reject(new Error('Dodaj zdjęcia'))
                  }
                }
              }
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <
              Card
                title='Zdjęcia Twojej pracy'
                className={isMobile ? 'on-mobile' : ''}
              >
                {fields.map(({ key, name, ...restField }) => (
                  <Row gutter={[3, 0]}>
                    <Col xs={21} md={22} lg={22}>
                      <Form.Item
                        name='image'
                        label='Zdjęcie Twojej pracy'
                        className='inspiration-upload'
                        {...formItemLayout}
                        extra={
                          !this.state.uploadStatuses[key] && <Dragger
                            action={settings.api_url + `/api/certificate/request/${this.props.training?.id}/upload`}
                            disabled={loading}
                            headers={headers}
                            accept='image/png, image/jpeg'
                            onChange={(x) => {
                              if(x.file.status === 'done') {
                                // this.props.getUploadedFilePreview()
                                const uploadStatuses = this.state.uploadStatuses

                                uploadStatuses[key] = true

                                this.setState({ uploadStatuses })
                              }
                            }}
                          >
                            <p>Kliknij by przesłać plik lub przeciągnij go tutaj</p>
                          </Dragger>
                        }
                      >
                        {this.state.uploadStatuses[key] && <p>Plik przesłany</p>}
                      </Form.Item>

                    </Col>
                  {/*<Col xs={3} md={2} lg={2} className={!isMobile ? 'form-item-actions' : ''}>
                    <Popconfirm
                      title='Czy na pewno chcesz usunąć ten element?'
                      onConfirm={() => this.removePurchasedIngredient(name, remove, key, { ...restField })}
                      {...popconfirmItemDeleteProps}
                    >
                      <MinusCircleOutlined
                        className='dynamic-delete-button'
                        title='Usuń'
                      />
                    </Popconfirm>
                  </Col>*/}
                </Row>
                ))}

                <Form.ErrorList errors={errors} />

                <Button
                  type='secondary'
                  onClick={add}
                  icon={<PlusOutlined />}
                >
                  Dodaj zdjęcie
                </Button>
              </Card>
            )}
          </Form.List>

          <Button className='sc-button' style={{ marginTop: '20px' }} ref={this.resultRef} htmlType='submit' type='primary' size='large' disabled={submitting}>
            Wyślij zgłoszenie{submitting && <LoadingOutlined style={{ fontSize: 14 }} spin />}
          </Button>
        </Form>
      </>
    )
  }
}

export default CertificateUploadForm
