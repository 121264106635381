import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './assets/css/App.css'
import 'react-chat-elements/dist/main.css'

import React from 'react'
import { Layout } from 'antd'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'
import { enquireScreen } from 'enquire-js'
import { connect } from 'react-redux'
import Header from './components/common/header'
import Footer from './components/common/footer'
// import CookieConsent from 'react-cookie-consent'

const { Content } = Layout

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const { location = {} } = typeof window !== 'undefined' ? window : {}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      isMobile,
      show: !location.port
    }
  }

  componentDidMount () {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b })
    })
    if (location.port) {
      setTimeout(() => {
        this.setState({
          show: true
        })
      }, 500)
    }
  }

  render () {
    const { authenticated } = this.props
    const { isMobile } = this.state

    return (
      <div className='App'>
        <Router>
          {/*<CookieConsent
            buttonText='Ok, zrozumiałem/am'
            cookieName='cookie-prompt'
            style={{ justifyContent: 'center' }}
          >
            W serwisie wykorzystujemy pliki cookies. W celu uzyskania więcej szczegółów zapraszamy do przeczytania{' '}
            <Link to='/privacy-policy'>Polityki prywatności</Link>{' '}
            i{' '}
            <Link to='/cookie-policy'>Polityki cookie</Link>
          </CookieConsent>*/}
          <Header
            isAuthenticated={authenticated}
            isMobile={isMobile}
          />
          <Content style={{ padding: '0' }} className={(isMobile ? ' ant-layout-content-mobile' : '')}>
            <Routes isMobile={isMobile} version={this.props.version} />
          </Content>
          <Footer
            id='Footer1_0'
            key='Footer1_0'
            isMobile={this.state.isMobile}
            authenticated={authenticated}
            version={this.props.version}
          />
        </Router>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  authenticated: state.appRedux.authenticated
})

const RoutesContainer = connect(
  mapStateToProps
)(App)

export default RoutesContainer
