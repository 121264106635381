import React from 'react'
import {
  Skeleton, Row, Col, Image, Button, Input, Space
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { withTranslation } from 'react-i18next'
import { menuTypeHook } from '../../components/common/menuTypeHook'
const { TextArea } = Input

class StyledCalendarShow extends React.Component {
  state = {
    loaded: false,
  }

  messageRef = React.createRef()

  handleSubmit = (action) => {
    this.props.sendCertificateResponse(action, this.messageRef?.current?.resizableTextArea?.textArea?.value)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.formData !== this.props.formData) {
      this.setState({
        loaded: true
      })
    }
  }

  render () {
    const { isMobile, formData } = this.props
    const { loaded } = this.state

    return (
      <>
        <div style={{ marginLeft: !isMobile ? 'auto' : '', marginRight: !isMobile ? 'auto' : '', padding: '0 20px', maxWidth: '800px' }}>
          <Skeleton active loading={!loaded} />
        </div>
        <div>
          <div style={{ display: loaded ? 'block' : 'none' }}>
            <Row>
              <Col xs={24} md={12}>
                {formData?.files?.map(file => {
                  return <Image
                    hoverable='true'
                    style={{ width: '200px', height: '200px' }}
                    src={"data:image/png;base64, " + file}
                    preview={{
                      src: "data:image/png;base64, " + file
                    }}
                  />
                })}
              </Col>
              <Col xs={24} md={12} style={ isMobile ? { paddingLeft: '20px', paddingRight: '20px' } : {} }>
                <Space direction='vertical' size='middle' style={{ display: 'flex' }}>

                  {formData?.message && <>
                    <p>Wiadomość od uczestnika:</p>

                    <p>{formData.message}</p>
                  </>}

                  <p>Dodaj dodatkową wiadomość zwrotną dla uczestnika (np. uwagi co poprawić, jakaś specjalna pochwała itp)</p>
                  <TextArea ref={this.messageRef} />

                  <Space>
                    <Button type='primary' danger onClick={() => this.handleSubmit('reject')} disabled={this.props.downloadingFile}>
                      Odrzuć pracę {this.props.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin /></>}
                    </Button>
                    <Button type='primary' onClick={() => this.handleSubmit('accept')} disabled={this.props.downloadingFile}>
                      Zatwierdź pracę {this.props.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin /></>}
                    </Button>
                  </Space>
                </Space>
              </Col>
            </Row>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(menuTypeHook(StyledCalendarShow))
