import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import ScrollToTop from './components/common/scrollToTop'
import Main from './components/main'
// import RegulationsPublic from './components/main/regulationsPublic'
// import RegulationsPanel from './components/main/regulationsPanel'
// import Regulations20221010 from './components/main/regulations20221010'
// import Regulations20240301 from './components/main/regulations20240301'
// import Regulations from './components/main/regulations20240508'
import Regulations20240718 from './components/main/regulations20240718'
// import PrivacyPolicy from './components/main/privacyPolicy'
// import PrivacyPolicy20240301 from './components/main/privacyPolicy20240301'
// import PrivacyPolicy from './components/main/privacyPolicy20240508'
import PrivacyPolicy20240718 from './components/main/privacyPolicy20240718'
// import CookiePolicy from './components/main/cookiePolicy'
import Help from './components/main/help'
import Stats from './components/main/stats'
import Contact from './components/main/contact'
import Community from './components/main/community'
import PanelIndex from './components/main/panel'
import UserLogin from './components/user/login'
import UserResetPassword from './components/user/resetPassword'
import UserResetPasswordChange from './components/user/resetPasswordChange'
// import GetPassword from './components/main/getPassword'
import Register from './components/user/register'
import UserProfile from './components/user/profile'
import InfoDataInfo from './components/main/infoDataInfo'
import InfoDeleteAccount from './components/main/infoDeleteAccount'
// import SubSelect from './components/userPurchase/subSelect'
import PaymentConfirm from './components/userPurchase/paymentConfirm'
import PaymentsList from './components/userPurchase'
import PaymentsShow from './components/userPurchase/show'
// import ChangeSub from './components/userPurchase/changeSub'
import CancelSub from './components/userPurchase/cancelSub'

import CalculatorsIndex from './components/main/calculators'
import CalcIndex from './components/calc'
import CalcPortionsIndex from './components/calcPortions'
import CalcProIndex from './components/calcPro'
// import ModuleNotLogged from './components/main/notLogged'

import MainRecipeIndex from './components/recipe/recipeIndex'
import MainRecipeShow from './components/recipe/recipeShow'

import MyRecipeIndex from './components/myrecipes'
import MyRecipeAddRecipe from './components/myrecipes/addRecipe'
import MyRecipePantryIndex from './components/myrecipes/pantry'
import MyRecipeEditRecipe from './components/myrecipes/editRecipe'
import MyRecipeShowRecipe from './components/myrecipes/showRecipe'
import MyRecipeAddPantry from './components/myrecipes/addPantry'
import MyRecipeEditPantry from './components/myrecipes/editPantry'

import AddOrder from './components/calcPro/addOrder'
import EditOrder from './components/calcPro/editOrder'
import AddRecipe from './components/calcPro/addRecipe'
import PantryIndex from './components/calcPro/pantry'
import RecipeIndex from './components/calcPro/recipe'
import EditRecipe from './components/calcPro/editRecipe'
import AddPantry from './components/calcPro/addPantry'
import EditPantry from './components/calcPro/editPantry'
import CalendarShow from './components/calcPro/calendarShow'
import OrderConfig from './components/calcPro/orderConfig'

import PromoIndex from './components/promo'
import PromoShow from './components/promo/show'

import TopperIndex from './components/topper'
import TopperShow from './components/topper/topperShow'

import PrintoutIndex from './components/printout'
import PrintoutShow from './components/printout/printoutShow'
import PrintoutTagShow from './components/printout/tagShow'
import EnlargeImageIndex from './components/enlargeImage'

import TrainingIndex from './components/training'
import TrainingShow from './components/training/show'
import TrainingAuthorIndex from './components/trainingAuthor'
import TrainingAuthorShow from './components/trainingAuthor/show'

import InspirationIndex from './components/inspiration'

import CertificateIndex from './components/certificates'
import CertificateShow from './components/certificates/show'

import { setAuth, setInstallPromptShowed, setUserRoles, setActiveModules, setGlobalMessage, setSubType } from './store/actions'
import { getCookie, getDeviceData } from './utils/helpers'
import withTracker from './utils/withTracker'
import { dummy } from './utils/actions'

class Routes extends React.Component {
  componentDidMount () {
    const { setAuth, setInstallPromptShowed } = this.props
    const token = getDeviceData('auth')
    const installPromptShowed = getCookie('install_prompt_showed')

    if (token) {
      setAuth(true)
    }

    dummy().then((result) => {
      this.props.setUserRoles(result.roles)
      this.props.setActiveModules(result.active_modules)
      this.props.setGlobalMessage(result.global_message)
      this.props.setSubType(result.sub_type)
    })

    if (installPromptShowed) {
      setInstallPromptShowed(true)
    }
  }

  render () {
    // const { authenticated } = this.props

    const CalcWithTracker = withTracker(CalcIndex)
    const CalcProWithTracker = withTracker(CalcProIndex)
    const CalcPortionsWithTracker = withTracker(CalcPortionsIndex)

    const MainRecipeIndexWithTracker = withTracker(MainRecipeIndex)
    const MainRecipeShowWithTracker = withTracker(MainRecipeShow)

    const MyRecipeWithTracker = withTracker(MyRecipeIndex)
    const MyRecipePantryIndexWithTracker = withTracker(MyRecipePantryIndex)
    const MyRecipeAddPantryWithTracker = withTracker(MyRecipeAddPantry)
    const MyRecipeEditPantryWithTracker = withTracker(MyRecipeEditPantry)
    const MyRecipeAddRecipeWithTracker = withTracker(MyRecipeAddRecipe)
    const MyRecipeEditRecipeWithTracker = withTracker(MyRecipeEditRecipe)
    const MyRecipeShowRecipeWithTracker = withTracker(MyRecipeShowRecipe)

    const PromoShowWithTracker = withTracker(PromoShow)
    const UserProfileWithTracker = withTracker(UserProfile)
    const RegisterWithTracker = withTracker(Register)

    const AddOrderWithTracker = withTracker(AddOrder)
    const EditOrderWithTracker = withTracker(EditOrder)
    const PantryIndexWithTracker = withTracker(PantryIndex)
    const AddPantryWithTracker = withTracker(AddPantry)
    const EditPantryWithTracker = withTracker(EditPantry)
    const RecipeIndexWithTracker = withTracker(RecipeIndex)
    const AddRecipeWithTracker = withTracker(AddRecipe)
    const EditRecipeWithTracker = withTracker(EditRecipe)
    const CalendarShowWithTracker = withTracker(CalendarShow)
    const OrderConfigWithTracker = withTracker(OrderConfig)

    const TopperShowWithTracker = withTracker(TopperShow)
    const PrintoutShowWithTracker = withTracker(PrintoutShow)
    const PrintoutTagShowWithTracker = withTracker(PrintoutTagShow)
    const EnlargeImageWithTracker = withTracker(EnlargeImageIndex)
    const TrainingShowWithTracker = withTracker(TrainingShow)
    const TrainingAuthorIndexWithTracker = withTracker(TrainingAuthorIndex)
    const TrainingAuthorShowWithTracker = withTracker(TrainingAuthorShow)

    const InspirationWithTracker = withTracker(InspirationIndex)
    // const SubSelectWithTracker = withTracker(SubSelect)
    const PaymentConfirmWithTracker = withTracker(PaymentConfirm)
    const PaymentsListWithTracker = withTracker(PaymentsList)
    const PaymentsShowWithTracker = withTracker(PaymentsShow)
    const HelpWithTracker = withTracker(Help)

    // {authenticated
    //   ? (
    return (
      <>
        <ScrollToTop />
            <Switch>
              <Route exact path='/panel' component={withTracker(PanelIndex)} />
              <Route exact path='/calculators' component={withTracker(CalculatorsIndex)} />
              <Route exact path='/calendar/:year/:month/:day' render={(props) => <CalendarShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/calendar/:year/:month' render={(props) => <CalendarShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/order' render={(props) => <CalcProWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/order/config' render={(props) => <OrderConfigWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/order/add/:date' render={(props) => <AddOrderWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/order/add' render={(props) => <AddOrderWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/order/:id' render={(props) => <EditOrderWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/pantry/add' render={(props) => <AddPantryWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/pantry/:id' render={(props) => <EditPantryWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/pantry' render={(props) => <PantryIndexWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/recipe/add' render={(props) => <AddRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/recipe/:id' render={(props) => <EditRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/recipe' render={(props) => <RecipeIndexWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/przepisy' render={(props) => <MainRecipeIndexWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/przepisy/:slug' render={(props) => <MainRecipeShowWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/myrecipes' render={(props) => <MyRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/pantry/add' render={(props) => <MyRecipeAddPantryWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/pantry/:id' render={(props) => <MyRecipeEditPantryWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/pantry' render={(props) => <MyRecipePantryIndexWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/add' render={(props) => <MyRecipeAddRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/:id/edit' render={(props) => <MyRecipeEditRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/myrecipes/:id' render={(props) => <MyRecipeShowRecipeWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/topper' component={withTracker(TopperIndex)} />
              <Route exact path='/topper/:id' render={(props) => <TopperShowWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/grafiki-do-wydrukow' component={withTracker(PrintoutIndex)} />
              <Route exact path='/grafiki-do-wydrukow/tag/:tag' render={(props) => <PrintoutTagShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/grafiki-do-wydrukow/:id' render={(props) => <PrintoutShowWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/training/author' render={(props) => <TrainingAuthorIndexWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/training/author/:id' render={(props) => <TrainingAuthorShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/training' component={withTracker(TrainingIndex)} />
              <Route exact path='/training/:id' render={(props) => <TrainingShowWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/inspiracje-ai' render={(props) => <InspirationWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/powieksz-grafike' render={(props) => <EnlargeImageWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/calculator' render={(props) => <CalcWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/promotions' component={withTracker(PromoIndex)} />
              <Route exact path='/promotions/:id' render={(props) => <PromoShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/partnerzy' component={withTracker(PromoIndex)} />
              <Route exact path='/partnerzy/:id' render={(props) => <PromoShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/profile' render={(props) => <UserProfileWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/login' component={withTracker(UserLogin)} />
              <Route exact path='/help' render={(props) => <HelpWithTracker {...props} isMobile={this.props.isMobile} version={this.props.version} />} />
              <Route exact path='/calculator-portions' render={(props) => <CalcPortionsWithTracker {...props} isMobile={this.props.isMobile} />} />

              <Route exact path='/bstats' render={() => <Stats />} />
              {/*authenticated ? <Route exact path='/regulations' component={withTracker(RegulationsPanel)} />
                : <Route exact path='/regulations' component={withTracker(RegulationsPublic)} />*/}

              {/*<Route exact path='/regulations20230619' component={withTracker(Regulations20230619)} />*/}

              {/*<Route exact path='/pakiety' render={(props) => <SubSelectWithTracker {...props} isMobile={this.props.isMobile} />} />*/}
              <Route exact path='/payment_confirm' render={(props) => <PaymentConfirmWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/historia-platnosci' render={(props) => <PaymentsListWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/historia-platnosci/:id' render={(props) => <PaymentsShowWithTracker {...props} isMobile={this.props.isMobile} />} />
              {/*<Route exact path='/zmien-pakiet' component={withTracker(ChangeSub)} />*/}
              <Route exact path='/anuluj-subskrypcje' component={withTracker(CancelSub)} />
              <Route exact path='/community' component={withTracker(Community)} />

              <Route exact path='/certificate' component={withTracker(CertificateIndex)} />
              <Route exact path='/certificate/:id' component={withTracker(CertificateShow)} />

              <Route exact path='/data-info' component={withTracker(InfoDataInfo)} />
              <Route exact path='/delete-account' component={withTracker(InfoDeleteAccount)} />
              {/*<Route exact path='/register' render={(props) => <RegisterWithTracker {...props} isMobile={this.props.isMobile} />} />*/}

              {/*this.props.userRoles?.includes('ROLE_TESTER') && <Route exact path='/regulations' component={withTracker(RegulationsPanel)} />*/}
          {/*  </Switch>)
          : (
            <Switch>*/}
              <Route exact path='/register' render={(props) => <RegisterWithTracker {...props} isMobile={this.props.isMobile} />} />
              <Route exact path='/login' component={withTracker(UserLogin)} />

              {/*<Route exact path='/calculator' render={(props) => <ModuleNotLogged {...props} moduleName='kalkulatora do wycen' />} />
              <Route exact path='/calculators' render={(props) => <ModuleNotLogged {...props} moduleName='kalkulatorów' />} />
              <Route exact path='/calculator-portions' render={(props) => <ModuleNotLogged {...props} moduleName='kalkulatora porcji' />} />
              <Route exact path='/myrecipes' render={(props) => <ModuleNotLogged {...props} moduleName='bazy Twoich przepisów' />} />
              <Route path='/training' render={(props) => <ModuleNotLogged {...props} moduleName='szkoleń' authenticated={false} />} />
              <Route path='/promotions' render={(props) => <ModuleNotLogged {...props} moduleName='polecanych partnerów' />} />
              <Route path='/topper' render={(props) => <ModuleNotLogged {...props} moduleName='projektów topperów' />} />
              <Route path='/grafiki-do-wydrukow' render={(props) => <ModuleNotLogged {...props} moduleName='grafik do wydruków' />} />
              <Route path='/order' render={(props) => <ModuleNotLogged {...props} moduleName='systemu zamówień' />} />
              <Route exact path='/inspiracje-ai' render={(props) => <ModuleNotLogged {...props} moduleName='generowania inspiracji AI' />} />
              <Route exact path='/powieksz-grafike' render={(props) => <ModuleNotLogged {...props} moduleName='powiększanie grafik' />} />*/}

              {/*<Route exact path='/regulations-20240301' component={withTracker(Regulations20240301)} />
              <Route exact path='/privacy-policy-20240301' component={withTracker(PrivacyPolicy20240301)} />
              <Route exact path='/regulations-20240508' component={withTracker(Regulations20240508)} />
              <Route exact path='/privacy-policy-20240508' component={withTracker(PrivacyPolicy20240508)} />*/}

              {/*<Route exact path='/regulations' component={withTracker(Regulations)} />*/}
              <Route exact path='/regulations' component={withTracker(Regulations20240718)} />
              <Route exact path='/regulations20240718' component={withTracker(Regulations20240718)} />
              {/*<Route exact path='/privacy-policy' component={withTracker(PrivacyPolicy)} />*/}
              <Route exact path='/privacy-policy' component={withTracker(PrivacyPolicy20240718)} />
              <Route exact path='/privacy-policy20240718' component={withTracker(PrivacyPolicy20240718)} />
              {/*<Route exact path='/cookie-policy' component={withTracker(CookiePolicy)} />*/}
              <Route exact path='/contact' component={withTracker(Contact)} />
              {/*<Route exact path='/jak-zdobyc-haslo' component={withTracker(GetPassword)} />*/}
              <Route exact path='/reset-password' component={withTracker(UserResetPassword)} />
              <Route exact path='/reset-password/:token' component={withTracker(UserResetPasswordChange)} />
              <Route exact path='/data-info' component={withTracker(InfoDataInfo)} />
              <Route exact path='/delete-account' component={withTracker(InfoDeleteAccount)} />
              {window.matchMedia('(display-mode: standalone)').matches ? (
                <Route exact path='/' component={withTracker(UserLogin)} />
              ) : (
                <Route component={withTracker(Main)} />
              )}
            </Switch>
      </>
    )
  }
// )}

}

const mapStateToProps = state => ({
  // authenticated: state.appRedux.authenticated,
  // userRoles: state.appRedux.userRoles
})

const mapDispatchToProps = {
  setAuth,
  setInstallPromptShowed,
  setUserRoles,
  setActiveModules,
  setGlobalMessage,
  setSubType
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)

export default RoutesContainer
