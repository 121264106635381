import React from 'react'
import { getPaymentStats } from '../../utils/actions'

class Stats extends React.Component {
  state = {}

  componentDidMount () {
    getPaymentStats().then((json) => {
      this.setState({ stats: json })
    })
  }

  render () {
    const { stats } = this.state
    return (
      <div>
        <h1>{(stats && stats.total_sum + ' zł') || ''}</h1>
        {stats?.subs && Object.keys(stats?.subs).map(amount =>
          <h1>{amount} zł x {stats?.subs[amount].count} = {stats?.subs[amount].sum} zł</h1>
        )}
      </div>
    )
  }
}

export default Stats
