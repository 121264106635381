/* eslint no-unused-expressions: "off" */
import React from 'react'
import {
  PageHeader, Row, Col, Typography, Divider, Avatar, Button, Anchor, List, Rate, Empty,
  Form, Input, Modal, Space, Alert, Image, Upload, Segmented, Menu, Badge, Dropdown, Popover //Affix
} from 'antd'
import QRCode from 'react-qr-code'
import ReactGA from 'react-ga4'
import ReactPlayer from 'react-player'
import settings from '../../config'
import ReactMarkdown from 'react-markdown'
import { Link } from 'react-router-dom'
import { MobileOutlined, InstagramOutlined, FacebookOutlined, LinkOutlined, LoadingOutlined, UserOutlined, CloudDownloadOutlined, PaperClipOutlined, CameraOutlined, DeleteOutlined, ReloadOutlined, ArrowDownOutlined } from '@ant-design/icons'
import { subName } from '../../utils/helpers'
import CtaBox from '../common/ctaBox'
import RecipeShowItem from '../../components/recipe/recipeShowItem'
// import Unsleep from '../common/unsleep'
import { MessageList } from "react-chat-elements"
import Countdown from 'react-countdown';
import CertificateUploadForm from './certificateUploadForm'
import { getDeviceData } from '../../utils/helpers'
import Unsleep from '../common/unsleep'
import Vimeo from '@u-wave/react-vimeo';
import Player from '@vimeo/player';
import TrainingItem from './item'

const { Paragraph, Title } = Typography
const { Link: LinkAnchor } = Anchor
// const { Dragger } = Upload

const emojis = ['❤️', '🙂', '🙁', '😯', '😍', '😂', '👍']

class StyledTrainingShow extends React.Component {
  state = {
    playedSeconds: 0,
    playClicked: false,
    showSetCertificateText: false,
    certificateText: undefined,
    showSetCertificateType: undefined,
    justUploaded: undefined,
    view: 'live_chat'
  }

  formRef = React.createRef()
  playerRef = React.createRef()
  player2Ref = React.createRef()
  player3Ref = React.createRef()
  messageRef = React.createRef()
  messagesEndRef = React.createRef()
  certificateTextRef = React.createRef()
  hiddenRef = React.createRef()
  pictureRef = React.createRef()

  scrollToBottom = (force) => {
    if (this.messagesEndRef.current.scrollHeight - this.messagesEndRef.current.scrollTop < 700 || force) {
      setTimeout(() => { this.messagesEndRef.current.scrollTop = this.messagesEndRef.current.scrollHeight }, 500)
    } else {
      this.setState({ newMessages: true })
    }
  }

  showSetCertificateText = (showSetCertificateText, showSetCertificateType) => {
    this.setState({ showSetCertificateText, showSetCertificateType })
  }

  info = () => {
    Modal.info({
      content: (
        <p>Aby zmienić jakość transmisji na wyższą, kliknij w ikonkę zębatki <img src='/images/player_help0.png' alt='zębatka'/> w prawym dolnym rogu wideo, następnie ustaw opcję Quality np na 540p lub 720p.</p>
      )
    })
  }

  setView = (view, notFullScreen) => {
    this.setState({ view })
    if (!notFullScreen && view === 'chat') {
      document.body.requestFullscreen()
    }
  }

  setCertificateText = () => {
    this.formRef.current.validateFields()
      .then((values) => {
        this.setState({ certificateText: values.text })
        this.showSetCertificateText(false)
        this.props.downloadCertificate(this.state.showSetCertificateType, values.text)
      })
      .catch((errorInfo) => {
      })
  }

  componentDidMount() {
    const { singleData } = this.props

    this.props.setScrollToBottom && this.props.setScrollToBottom(this.scrollToBottom)

    ReactGA.event({ category: 'Training', action: 'Training Viewed: ' + singleData.id })

    if (singleData?.type === 'live' && singleData?.live_url) {
      const player = new Player(this.player3Ref?.current)
      this.setState({ player })
    }

    if (this.props.onlyChat) {
      this.setView('chat', true)
    }
  }

  clickToPlay = () => {
    const { singleData } = this.props

    this.setState({ playClicked: true }, async () => {
      if (singleData.play_time && !singleData.vimeo_url && this.playerRef?.current) {
        this.playerRef.current.seekTo(singleData.play_time)
      }

      // if (singleData.play_time && singleData.vimeo_url && this.player2Ref?.current) {
      //   await this.player2Ref.current.player.setCurrentTime(singleData.play_time)
      // }
    })
  }

  componentWillUnmount() {
    // if video is still playing, save current time on component unmount
    const { singleData } = this.props

    if (!singleData.vimeo_url && this.playerRef?.current?.player?.isPlaying) {
      this.props.updateTrainingPlay(this.playerRef.current.getCurrentTime())
    }
  }

  onSeek = (x) => {
    this.setState({ playedSeconds: 0 })

    if (this.props.singleData.vimeo_url) {
      this.setState({ videoStart: x.seconds })
    }
  }

  onProgress = () => {
    this.setState({ playedSeconds: this.state.playedSeconds + 2 })
  }

  onStart = () => {
    this.props.updateTrainingPlay(0, false, 0)
    this.setState({ playedSeconds: 0 })

    ReactGA.event({ category: 'Training', action: 'Training Played: ' + this.props.singleData.id })
  }

  replyToMessage = (data) => {
    this.props.setReply && this.props.setReply({ username: data.title, user_id: 123, message: data.text })
    this.messageRef.current?.focus()
  }

  handlePause = async () => {
    const { singleData } = this.props

    let currentTime
    let duration

    if (singleData.vimeo_url) {
      currentTime = await this.player2Ref?.current?.player?.getCurrentTime()
      duration = await this.player2Ref?.current?.player?.getDuration()

    } else {
      currentTime = this.playerRef.current.getCurrentTime()
      duration = this.playerRef.current.getDuration()
    }

    let finished = false

    const proportion = currentTime / duration
    const wasFinished = this.props.singleData.finished

    // if 95% of video was watched, mark it as finished
    if (proportion > 0.99) {
      finished = true

      if (!wasFinished && !this.props.opinionNotNow) {
        this.props.setModalVisibility(true)
      }
    }

    let playedSeconds

    if (singleData.vimeo_url) {
      playedSeconds = Math.round(currentTime - this.state.videoStart)
      this.props.updateTrainingPlay(currentTime, finished)
    } else {
      playedSeconds = this.state.playedSeconds
      this.props.updateTrainingPlay(currentTime, finished, playedSeconds)
    }
  }


  onPause = (x) => {
    this.handlePause()

    this.setState({ playedSeconds: 0, isPlaying: false })
  }

  cameraNumber = 0

  initializeMedia = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((localMediaStream) => {
        this.fireMedia()
      })
      .catch((error) => {
        console.log('Rejected!', error)
      })
    // navigator.permissions.query({ name: 'camera' })
    // .then((permissionObj) => {
    //   if (permissionObj.state !== 'granted') {
    //     navigator.mediaDevices
    //       .getUserMedia({ video: true })
    //       .then((localMediaStream) => {
    //         this.fireMedia()
    //       })
    //       .catch((error) => {
    //         console.log('Rejected!', error)
    //       })
    //   } else {
    //     this.fireMedia()
    //   }
    // })
    // .catch((error) => {
    //   console.log('Got error :', error)
    // })
  }

  fireMedia = async () => {
    this.setState({ imageDataURL: null, capturing: true });
    this.player.style.display = 'block'

    if (!('mediaDevices' in navigator)) {
      navigator.mediaDevices = {};
    }

    if (!('getUserMedia' in navigator.mediaDevices)) {
      navigator.mediaDevices.getUserMedia = function (constraints) {
        var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia

        if (!getUserMedia) {
          return Promise.reject(new Error('getUserMedia Not Implemented'))
        }

        return new Promise((resolve, reject) => {
          getUserMedia.call(navigator, constraints, resolve, reject)
        });
      };
    }

    const videoInputs = await this.getListOfVideoInputs()

    if (videoInputs.length) {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: {
              exact: videoInputs[this.cameraNumber].deviceId,
            },
          }
        })
        .then((stream) => {
          this.player.srcObject = stream;
        })
        .catch((error) => {
          console.error(error);

          if (videoInputs.length > 1) {
            this.switchCamera()
          }
        })
    } else {
      alert("Urządzenie nie ma dostępnych aparatów")
    }
  }

  capturePicture = () => {
    const player = this.player
    this.setState({ processingImage: true })
    player.style.display = 'none'

    const { singleData } = this.props
    var canvas = this.pictureRef.current
    canvas.width = this.player.videoWidth
    canvas.height = this.player.videoHeight
    var contex = canvas.getContext('2d')
    contex.drawImage(this.player, 0, 0, canvas.width, canvas.height)

    this.player.srcObject?.getVideoTracks().forEach((track) => {
      track.stop()
    })

    const _this = this

    canvas.toBlob(function(blob) {
      const formData = new FormData();
      formData.append('file', blob, 'filename.png')

      const headers = {}
      headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

      fetch(settings.api_url + `/api/chat/${singleData.id}/upload`, {
        method: 'POST',
        body: formData,
        headers
      })
        .then((response) => response.json())
        .then((data) => {
          _this.props.setJustUploaded(data?.filename)
          _this.setState({ capturing: false, processingImage: false });
        })
        .catch((error) => {
          console.error('Error uploading image:', error)
        })
    })
  }

  cancelCapturing = () => {
    this.setState({ capturing: false })
    this.player.style.display = 'none'
    this.player.srcObject?.getVideoTracks().forEach((track) => {
      track.stop()
    })
  }

  switchCamera = async () => {
    const listOfVideoInputs = await this.getListOfVideoInputs();

    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {
        this.player.srcObject?.getVideoTracks().forEach((track) => {
          track.stop()
        })
      }

      if (this.cameraNumber + 1 === listOfVideoInputs.length) {
        this.cameraNumber = 0;

      } else {
        this.cameraNumber = this.cameraNumber + 1
      }

      this.initializeMedia()
    } else if (listOfVideoInputs.length === 1) {
      alert('Urządzenie ma tylko jeden aparat')
    } else {
      alert('Urządzenie nie ma żadnych dostępnych aparatów')
    }
  }

  getListOfVideoInputs = async () => {
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices()
    return enumerateDevices.filter((device) => device.kind === 'videoinput')
  }

  sendEmoji = (emoji) => {
    const { messagePosition } = this.state
    const target = this.messageRef?.current
    const cursorPosition = messagePosition !== undefined ? messagePosition : target.input.value.length
    const textBeforeCursorPosition = target.input.value.substring(0, cursorPosition)
    const textAfterCursorPosition = target.input.value.substring(cursorPosition, target.input.value.length)

    this.props.formRef?.current?.setFieldsValue({ message: textBeforeCursorPosition + emoji + textAfterCursorPosition })
  }

  setQuality = async (liveQuality) => {
    // await this.player3Ref?.current?.player?.setQuality(liveQuality)
    await this.state.player?.setQuality(liveQuality)
    this.setState({ liveQuality })
  }

  render () {
    const { makeRedirection, singleData, isMobile, authenticated, user, liveStatus, justUploaded, isModerator, chatConnected } = this.props
    const { playClicked, view, capturing, processingImage, newMessages, liveQuality } = this.state

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
      if (completed) {
        return 'Transmisja rozpocznie się lada moment'
      } else {
        return <span>{days} dni {hours} godzin {minutes} minut {seconds} sekund</span>
      }
    }

    const addOpinionButton = (authenticated && !singleData.trial && singleData.user_added_opinion === false && !this.props.addedOpinion && <> <Button type='link' onClick={() => this.props.setModalVisibility(true)}>Dodaj swoją opinię!</Button></>)
    const selectOptions = [{ label: 'Live i czat', value: 'live_chat', key: 'live_chat' }, { label: 'Tylko czat', value: 'chat', key: 'chat' }]

    const menu = (
      <Menu>
        {emojis.map(e => <Menu.Item style={{ fontSize: '20px' }} onClick={() => this.sendEmoji(e)}>
          {e}
        </Menu.Item>)}
      </Menu>)

    const loginIfPurchased = ((['sub', 'live'].includes(singleData.type) || singleData.private) && singleData.type !== '' && !authenticated &&
      <p style={{ fontWeight: '300', fontSize: '13px', marginTop: '20px' }}>{singleData?.purchase_url ? 'Jeśli już kupiłaś to szkolenie lub masz subskrypcję' : 'Jeśli już masz subskrypcję'}, <Link to={{
        pathname: '/login',
        state: { redirect: ['/training/' + singleData.slug] }
      }}>zaloguj się</Link></p>)

    const isAccessRequired = (authenticated &&
      <p style={{ fontWeight: '300', fontSize: '13px', marginTop: '20px' }}>Jeśli już dołączałaś do szkolenia i nadal nie masz dostępu napisz koniecznie na <a href="mailto:kontakt@strefacukiernika.pl">kontakt@strefacukiernika.pl</a></p>)

    const ctaBoxes = (<>{singleData.type === 'free' && !authenticated &&
      <CtaBox
        text={'To szkolenie jest za darmo dla zalogowanych użytkowników'}
        toRoute='/register'
        redirect={['/training/' + singleData.slug]}
        buttonText='Dołącz do Strefy Cukiernika'
      />}

      {loginIfPurchased}

      {['live'].includes(singleData.type) && !singleData?.has_private_perms && (!user || user?.sub_type === 'free') &&
        <CtaBox
          text={<>To szkolenie LIVE jest dostępne bez dodatkowych opłat dla subskrybentów Strefy Cukiernika lub osób, które do niego dołączyły.</>}
          toRoute={singleData?.purchase_url}
          redirect={['/training/' + singleData.slug]}
          requiredSubs={['sub2', 'sub3']}
          buttonText='Dołączam do szkolenia'
        />}

      {['sub'].includes(singleData.type) && !singleData?.has_private_perms && (!user || user?.sub_type === 'free') &&
        <CtaBox
          text={<>To szkolenie jest dostępne bez dodatkowych opłat dla subskrybentów Strefy Cukiernika.<br/><br/><span style={{ fontWeight: 700 }}>Uzyskaj dostęp do tego i kolejnych szkoleń za jedyne {settings.subscription_price_gross / 100} zł miesięcznie.</span></>}
          toRoute={settings.subscription_purchase_url}
          redirect={['/training/' + singleData.slug]}
          requiredSubs={['sub2', 'sub3']}
          buttonText='Kupuję subskrypcję'
          additionalText={settings.subscription_purchase_promo}
          priceBefore={settings.subscription_price_gross_promo && (settings.subscription_price_gross_promo/100)}
        />}

      {['sub'].includes(singleData.type) && !singleData?.has_private_perms && (!user || user?.sub_type === 'free') && singleData?.purchase_url &&
        <CtaBox
          text={<span style={{ fontWeight: 700 }}>Kup to jedno szkolenie za {singleData?.purchase_price_gross / 100} zł</span>}
          toRoute={singleData?.purchase_url}
          redirect={['/training/' + singleData.slug]}
          requiredSubs={['sub2', 'sub3']}
          buttonText='Kupuję to szkolenie'
        />}

    {/*singleData.type === 'sub' && !authenticated &&
    <CtaBox
      text={<>To szkolenie jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
      toRoute='/register'
      redirect={['/training/' + singleData.slug]}
      requiredSubs={['sub2', 'sub3']}
      buttonText='Dołącz do Strefy Cukiernika'
    />}

    {singleData.type === 'sub' && authenticated && user?.sub_type === 'free' && !user?.trial_used &&
      <CtaBox
        text={<>To szkolenie jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
        toRoute='/pakiety'
        redirect={['/training/' + singleData.slug]}
        requiredSubs={['sub2', 'sub3']}
        buttonText='Wybierz pakiet testowy i obejrzyj to szkolenie'
      />}

    {singleData.type === 'sub' && authenticated && user?.sub_type === 'free' && user?.trial_used &&
      <CtaBox
        text={<>To szkolenie jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}</>}
        toRoute='/pakiety'
        redirect={['/training/' + singleData.slug]}
        requiredSubs={['sub2', 'sub3']}
        buttonText='Wybierz pakiet i obejrzyj to szkolenie'
      />}

    {singleData.type === 'sub' && authenticated && user?.sub_type === 'sub1' &&
      <CtaBox
        text={<>To szkolenie jest dostępne w pakietach {subName('sub2')} i {subName('sub3')}</>}
        toRoute='/zmien-pakiet'
        redirect={['/training/' + singleData.slug]}
        buttonText='Zmień pakiet aby uzyskać dostęp'
      />*/}</>)

    const headers = {}
    headers[settings.jwt_header || 'Authorization'] = 'Bearer ' + getDeviceData('auth')

    return (
      <>
        {<Modal title='Podaj co ma być wpisane w certyfikacie' visible={this.state.showSetCertificateText} onOk={this.setCertificateText} onCancel={() => this.showSetCertificateText(false)}>
          <Form ref={this.formRef}>
            <Form.Item
              name={'text'}
              rules={[{ required: true, message: 'Pole jest wymagane' },
              {
                message: 'Możesz podać maksymalnie 25 znaków',
                validator: (_, value) => {
                  if (value?.length > 25) {
                    return Promise.reject(new Error(''))
                  } else {
                    return Promise.resolve('Możesz podać maksymalnie 25 znaków')
                  }
                }
              }
            ]}
            >
              <Input placeholder='Możesz podać np. swoje imię i nazwisko' />
            </Form.Item>
          </Form>
        </Modal>}

        {(!isMobile || liveStatus !== 'started') && view === 'live_chat' && <PageHeader
          title={singleData.title}
          className='training-title'
          onBack={() => makeRedirection()}
        />}
        {isModerator && singleData.type === 'live' && <div style={{ padding: '0 20px 20px 0' }}>
          <Unsleep moduleName='Training Show' />
          <Segmented
            onChange={this.setView}
            options={selectOptions}
          />
        </div>}
        {this.props.upsellConfirmation &&
          <Alert
            message='Płatność zakończona pomyślnie :)'
            description={<>
              <p>Jeśli nagranie jeszcze się nie pokazało to <Button onClick={() => window.location.replace('/training/' + singleData.slug)}>odśwież stronę</Button></p>
              <p>W razie problemów pisz na <a href='mailto:kontakt@strefacukiernika.pl'>kontakt@strefacukiernika.pl</a> :)</p>
              </>}
            type='success'
            style={{ maxWidth: '500px', margin: '0 auto 20px auto', borderRadius: '15px' }}
          />}
        <Row className='training'>
          {view === 'live_chat' && <Col xs={24} md={{ span: 12, offset: liveStatus === 'started' ? 0 : 2 }} lg={{ span: liveStatus === 'started' ? 12 : 10, offset: liveStatus === 'started' ? 1 : 4 }} className='training-left'>
            {/*<h2 className='training-title'>{singleData.title}</h2>*/}

            {singleData.vimeo_url && playClicked &&
              <>
                <div style={{ height: 'auto', /*padding:'56.25% 0 0 0', position:'relative',*/ marginTop: isMobile && '20px' }}>
                  <Vimeo
                    className='training-vimeo-player'
                    video={singleData.vimeo_url}
                    ref={this.player2Ref}
                    onPause={this.onPause}
                    onSeeked={this.onSeek}
                    onEnd={this.handlePause}
                    onTimeUpdate={async (x) => {
                      if (x.seconds - this.state.videoStart > 5) {
                        const playedSeconds = x.seconds - this.state.videoStart
                        const currentTime = await this.player2Ref?.current?.player?.getCurrentTime()

                        this.props.updateTrainingPlay(currentTime, false, 5)

                        this.setState({ videoStart: x.seconds, playedSeconds: this.state.playedSeconds + 5 })
                      }
                    }}
                    onPlay={(x) => {
                      this.setState({ videoStart: x.seconds, isPlaying: true })

                      if (x.seconds === 0) {
                        this.props.updateTrainingPlay(0, false, 0)
                        this.setState({ playedSeconds: 0 })

                        ReactGA.event({ category: 'Training', action: 'Training Played: ' + this.props.singleData.id })
                      }
                    }}
                  />
                </div>
              </>}

            {view === 'live_chat' && singleData.type === 'live' && singleData.live_url &&
              <>
                <div style={{ padding: liveStatus === 'started' ? '56.25% 0 0 0' : '', position:'relative', marginTop: isMobile && '20px' }}>
                  <iframe
                    ref={this.player3Ref}
                    title='Transmisja live'
                    src={singleData.live_url}
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                    style={{ position: 'absolute', top:0, left:0, width:'100%', height:'100%', display: liveStatus === 'started' ? 'block' : 'none' }}
                    onLoad={async () => {
                      const player = new Player(this.player3Ref?.current)
                      const liveQuality = await player?.getQuality()
                      this.setState({ player, liveQuality })
                    }}
                    ></iframe>

                  {/*<Vimeo
                    className='training-vimeo-player2'
                    video={singleData.live_url}
                    ref={this.player3Ref}
                    // muted={false}
                    paused
                    onPlay={async (x) => {
                      console.log('live played')
                      // await this.player3Ref?.current?.player?.setMuted(false)
                    }}
                    onLoaded={async () => {
                      const liveQuality = await this.player3Ref?.current?.player?.getQuality()
                      this.setState({ liveQuality })

                       // await this.player3Ref?.current?.player?.setMuted(false)
                       await this.player3Ref?.current?.player?.pause()
                    }}
                  />*/}

                  {liveStatus === 'started' && !this.state.player && <LoadingOutlined spin style={{ position: 'absolute', top: '100px', fontSize: '100px' }}/>}

                  {liveStatus === 'started' && this.state.player && !this.state.livePlaying && <div
                      className='live-bg'
                      style={{
                        position: 'absolute', top: '0px', left: '0px', width: '100%', height: '100%',
                        backgroundColor: 'rgba(100, 100, 100, 0.5)'
                      }}
                      onClick={async () => {
                        // console.log('clicked before')
                        try {
                          await this.state.player.play()
                        } catch(err) {
                          console.log('Cannot start player', err)
                        }
                        // console.log('clicked after')
                        this.setState({ livePlaying: true })
                      }}
                    >
                    {<img
                      className='training-teaser-image2 training-teaser-image-click2'
                      src='/images/click-to-play.png'
                      title='Kliknij aby dołączyć do LIVE'
                      alt="Kliknij aby dołączyć do LIVE"
                      style={{ margin: '0 auto', left: 0, right: 0, position: 'absolute', top: isMobile ? '0' : '30px', width: '100%' }}
                    />}
                    <h1 style={{ backgroundColor: '#fff', margin: '0 auto', left: 0, right: 0, position: 'absolute', top: '30px', width: '50%' }}>Kliknij aby dołączyć do LIVE</h1>
                 </div>}
                </div>

                {liveStatus === 'started' && liveQuality && <Row style={{ marginTop: '20px', padding: '0 10px' }}>
                  <Col sm={24} md={24} lg={4}>
                    <Space style={{ marginBottom: '10px' }}>
                      <span>Jakość wideo:</span>
                    </Space>
                  </Col>
                  <Col sm={24} md={24} lg={20} style={{ textAlign: 'left' }}>
                    <Space wrap>
                      {(liveQuality === 'auto' && <span style={{ fontWeight: '700' }}>automatyczna</span>) || <Button size='small' onClick={() => this.setQuality('auto')}>automatyczna</Button>}
                      {(liveQuality === '240p' && <span style={{ fontWeight: '700' }}>zoptymalizowana</span>) || <Button size='small' onClick={() => this.setQuality('240p')}>zoptymalizowana</Button>}
                      {(liveQuality === '540p' && <span style={{ fontWeight: '700' }}>bardzo dobra</span>) || <Button size='small' onClick={() => this.setQuality('540p')}>bardzo dobra</Button>}
                      {(liveQuality === '720p' && <span style={{ fontWeight: '700' }}>najlepsza</span>) || <Button size='small' onClick={() => this.setQuality('720p')}>najlepsza</Button>}
                    </Space>
                  </Col>
                </Row>}

                {/*<p style={{ marginTop: '20px' }}>
                  Jeśli chcesz ustawić lepszą jakość transmisji, przeczytaj
                  <Button onClick={this.info} type='text' size='small' className='player-help-link'>instrukcję</Button>
                </p>*/}
              </>}

            {liveStatus === 'finished' &&
              <div className='training-live-board'>
                Transmisja zakończona. Dziękujemy za udział!
              </div>}

            {singleData.type === 'live' && liveStatus === 'started' && !singleData.video_available &&
              <div className='training-live-board'>
                <p style={{ marginTop: '20px' }}>TRANSMISJA LIVE ROZPOCZEŁA SIĘ</p>

                <img style={{ maxHeight: '100px', marginBottom: '20px', marginRight: '20px' }} src='/images/live.jpg' alt="LIVE" />
                <img style={{ maxHeight: '100px', marginBottom: '20px' }} className='training-teaser-imagex training-teaser-image-click-bgx' src={'/dimages/trainings/' + singleData.id + '-click.png?t=' + (new Date().getTime())} alt="Grafika szkolenia" />

                <p style={{ color: 'red' }}>Nie masz dostępu do tej transmisji</p>
                <p><a href={singleData.purchase_url}><Button className='cta-box-button'>Dołącz aby uzyskać dostęp</Button></a>

                {loginIfPurchased}
                {isAccessRequired}
              </p>
            </div>}

            {liveStatus === 'before' && singleData.type === 'live' && singleData.live_start_date &&
              <div className='training-live-board'>
                <img style={{ maxHeight: '100px' }} className='training-teaser-imagex training-teaser-image-click-bgx' src={'/dimages/trainings/' + singleData.id + '-click.png?t=' + (new Date().getTime())} alt="Grafika szkolenia" />

                <p style={{ marginTop: '20px' }}>Transmisja LIVE rozpocznie się za:</p>
                <Countdown date={Date.parse(singleData.live_start_date)} renderer={renderer} />
                {!singleData.video_available && <div style={{ marginTop: '20px' }}>
                  <p style={{ color: 'red' }}>Nie masz dostępu do tej transmisji</p>
                  <p><a href={singleData.purchase_url}><Button className='cta-box-button'>Dołącz aby uzyskać dostęp</Button></a></p>

                  {loginIfPurchased}
                  {isAccessRequired}
                  </div>}
              </div>}

            {singleData.type === 'teaser' && singleData.teaser_cta_link &&
              <div className='teaser-link'><a href={singleData.teaser_cta_link}>{singleData.teaser_cta_text}</a></div>}

            {singleData.type === 'teaser' && singleData.teaser_image &&
              <div><img className='training-teaser-image' src={singleData.teaser_image } alt='Zapowiedź szkolenia' /></div>}

            {playClicked && !singleData.vimeo_url &&
              <ReactPlayer
                url={settings.video_url + '/' + singleData.uuid + '.mp4'}
                controls
                width='100%'
                ref={this.playerRef}
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
                className='training-video'
                onStart={this.onStart}
                progressInterval={2000}
                onPause={this.onPause}
                onProgress={this.onProgress}
                onSeek={this.onSeek}
                config={{ file: {
                  attributes: {
                    controlsList: 'nodownload',
                    playsInline: true
                  }
                }}}
              />}

            {singleData.type !== 'live' && singleData.type !== 'teaser' && !playClicked && singleData.video_available && <div>
              <img className='training-teaser-image training-teaser-image-click-bg' src={'/dimages/trainings/' + singleData.id + '-click.png?t=' + (new Date().getTime())} alt="Grafika szkolenia" />
              <img className='training-teaser-image training-teaser-image-click' src='/images/click-to-play.png' title='Kliknij aby odtworzyć' alt="Kliknij aby odtworzyć" onClick={this.clickToPlay} />
            </div>}

            {singleData.type === '' && !singleData.video_available && singleData.private &&
              <div style={{ border: '1px solid #f0f0f0', borderRadius: '15px', padding: '20px' }}>
                <p>
                  Nie masz dostępu do wideo, ponieważ wybrany został pakiet szkolenia bez nagrania lub dostęp do nagrania wygasł.
                </p>
                {singleData.upsell_link && <>
                <p style={{ fontWeight: '700', fontSize: '20px' }}>Wykup dostęp do tego nagrania za {singleData.upsell_price_gross} zł</p>
                <p><Button type='primary'><a href={singleData.upsell_link}>Kupuję</a></Button></p>
                </>}
              </div>}

            {['sub', 'free', 'public'].includes(singleData.type) && !singleData.video_available/* && !singleData.private*/ && !singleData.teaser_image && <div>
              <img className='training-teaser-image' src={'/dimages/trainings/' + singleData.id + '-click.png?t=' + (new Date().getTime())} alt="Grafika szkolenia" />
              <img className='training-teaser-image training-teaser-image-click' src='/images/click-to-play.png' title='Kliknij aby odtworzyć' alt="Kliknij aby odtworzyć" onClick={() => this.props.setCtaModalVisible(true)} />
            </div>}

            {isMobile && ctaBoxes}

            {liveStatus !== 'started' && <>
              <Anchor targetOffset={60}>
                <LinkAnchor href="#description" title="O szkoleniu" />
                {/*singleData.what_to_learn &&<LinkAnchor href="#what_to_learn" title="Czego się nauczysz" />}
                {singleData.what_included &&<LinkAnchor href="#what_included" title="Co zawiera szkolenie" />*/}
                {singleData.tools &&<LinkAnchor href="#tools" title="Potrzebne narzędzia i materiały" />}
                {/*singleData.materials &&<LinkAnchor href="#materials" title="Materiały" />*/}
                {singleData.recipes?.length > 0 && <LinkAnchor href="#recipes" title="Dołączone przepisy" />}
                {singleData.has_files && <LinkAnchor href="#files" title="Do pobrania" />}
                {singleData.type !== 'teaser' && <LinkAnchor href="#opinions" title="Opinie" />}
                <LinkAnchor href="#author" title="O autorze" />
              </Anchor>

              <Typography style={{ marginTop: '20px' }}>
                <Paragraph style={{ textAlign: 'left' }}>
                  <Title level={4} id='description'>O szkoleniu</Title>
                  <ReactMarkdown>{singleData.description}</ReactMarkdown>
                </Paragraph>
              </Typography>

              {singleData.what_to_learn &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='what_to_learn'>Czego się nauczysz</Title>
                    <ReactMarkdown>{singleData.what_to_learn}</ReactMarkdown>
                  </Paragraph>
                </Typography>}

              {singleData.what_included &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='what_included'>Co zawiera szkolenie</Title>
                    <ReactMarkdown>{singleData.what_included}</ReactMarkdown>
                  </Paragraph>
                </Typography>}

              {singleData.tools &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='tools'>Narzędzia</Title>
                    <ReactMarkdown>{singleData.tools}</ReactMarkdown>
                  </Paragraph>
                </Typography>}

              {singleData.materials &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='materials'>Materiały</Title>
                    <ReactMarkdown>{singleData.materials}</ReactMarkdown>
                  </Paragraph>
                </Typography>}

              {singleData.additional_info &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='additional_info'>Dodatkowe uwagi</Title>
                    <ReactMarkdown>{singleData.additional_info}</ReactMarkdown>
                  </Paragraph>
                </Typography>}

              {singleData?.recipes?.length > 0 &&
                <Typography>
                  <Paragraph style={{ textAlign: 'left' }} id='recipes'>
                    <Title level={4}>Przepisy wykorzystane w tym szkoleniu</Title>
                      {singleData?.recipes?.map(recipe => (
                        <>
                          <Title level={3}>{recipe.name}</Title>
                          <RecipeShowItem
                            recipe={recipe}
                            imageMaxSize={200}
                            loaded={true}
                            setRecalcRecipeModalVisibility={this.setRecalcModalVisible}
                            recalcUsed={this.state.recalcUsed}
                            cancelRecalc={this.cancelRecalc}
                            authenticated={authenticated}
                            user={this.state.user}
                            redirect={this.redirect}
                            collapse
                            disableUnsleep
                            moduleName='Training Show'
                            recipeLink={'/przepisy/' + recipe.slug}
                            redirectRoute={['/training/' + singleData.slug]}
                          />
                          <Divider/>
                        </>
                      )
                    )}
                  </Paragraph>
                </Typography>
              }

              {singleData.has_files &&
                <Typography id='recipes'>
                  <Paragraph style={{ textAlign: 'left' }}>
                    <Title level={4} id='files'>Do pobrania</Title>
                    <ul>
                      {singleData.files.map((file, i) => (
                          <li style={{ lineHeight: '30px' }} key={i}>
                            {file.name}{' '}
                            {!this.props.isApple && <Button
                              onClick={() => this.props.downloadFile(file)}
                              size='small'
                              className='sc-button'
                              // disabled={(this.props.downloadingFile || !authenticated || (authenticated && ((singleData.type === 'sub' && user?.sub_type === 'free' && !user?.trial_used) || (singleData.type === 'sub' && user?.sub_type === 'free' && user?.trial_used) || (singleData.type === 'sub' && authenticated && user?.sub_type === 'sub1'))))}
                              // className='download'
                            >
                              Pobierz{this.props.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14 }} spin /></>}
                            </Button>}
                            {this.props.isApple && (this.props.hasPro ?
                              <Button size='small' className='sc-button'><a href={'/ijsfiuuidfhghfdhwer723ysdf/' + file.id + '.pdf'} download>Pobierz</a></Button> : <Button size='small' className='sc-button' onClick={() => this.props.setCtaModalVisible(true)}>Pobierz</Button>)}
                          </li>)
                      )}
                    </ul>

                    {/*singleData.has_files === true && singleData.type === 'free' && !authenticated &&
                      <CtaBox
                        text={'Pliki do tego szkolenia są dostępne za darmo dla zalogowanych użytkowników'}
                        toRoute='/register'
                        redirect={['/training/' + singleData.slug]}
                        buttonText='Dołącz do Strefy Cukiernika'
                      />}

                    {singleData.has_files === true && singleData.type === 'sub' && !authenticated &&
                      <CtaBox
                        text={<>Pliki do tego szkolenia są dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
                        toRoute='/register'
                        redirect={['/training/' + singleData.slug]}
                        buttonText='Dołącz do Strefy Cukiernika'
                      />}

                    {singleData.has_files === true && singleData.type === 'sub' && authenticated && user?.sub_type === 'free' && !user?.trial_used &&
                      <CtaBox
                        text={<>Pliki do tego szkolenia są dostępne w pakietach {subName('sub2')} i {subName('sub3')}, wypróbuj konto testowe przez 7 dni</>}
                        toRoute='/pakiety'
                        redirect={['/training/' + singleData.slug]}
                        buttonText='Wybierz pakiet testowy i pobierz pliki'
                      />}

                    {singleData.has_files === true && singleData.type === 'sub' && authenticated && user?.sub_type === 'free' && user?.trial_used &&
                      <CtaBox
                        text={<>Pliki do tego szkolenia są dostępne w pakietach {subName('sub2')} i {subName('sub3')}</>}
                        toRoute='/pakiety'
                        redirect={['/training/' + singleData.slug]}
                        buttonText='Wybierz pakiet i pobierz pliki'
                      />}

                    {singleData.has_files === true && singleData.type === 'sub' && authenticated && user?.sub_type === 'sub1' &&
                      <CtaBox
                        text={<>Pliki do tego szkolenia są dostępne w pakietach {subName('sub2')} i {subName('sub3')}</>}
                        toRoute='/zmien-pakiet'
                        redirect={['/training/' + singleData.slug]}
                        buttonText='Zmień pakiet aby uzyskać dostęp'
                      />*/}

                  </Paragraph>
              </Typography>}

              {singleData.type !== 'teaser' && <Typography id='opinions'>
                <Paragraph style={{ textAlign: 'left' }}>
                  <Title level={4} type='warning'>Opinie{singleData.opinions?.length > 0 && addOpinionButton}</Title>

                  {singleData.opinions?.length > 0 &&
                    <List
                      itemLayout="vertical"
                      size="large"
                      pagination={ singleData.opinions?.length > 3 ? { pageSize: 3 } : undefined }
                      dataSource={singleData.opinions}
                      renderItem={(item, i) => (
                        <List.Item
                          key={i}
                        >
                          <List.Item.Meta
                            avatar={<Avatar /*src={item.avatar}*/ size='large' icon={<UserOutlined />} />}
                            title={item.name}
                            description={<Rate /*tooltips={rateDesc}*/ disabled defaultValue={item.rating} />}
                          />
                          {item.opinion}
                        </List.Item>
                      )}
                    />}

                  {singleData.opinions?.length === 0 &&
                    <Empty description='Póki co brak opinii ;-)'>
                     {addOpinionButton}
                   </Empty>}
                </Paragraph>
              </Typography>}

              <Row style={{ marginTop: '40px' }}>
                <Col xs={8} lg={4}>
                  <Link to={'/training/author/' + singleData.author.id}><Avatar size={80} icon={<img alt={singleData.author.name + '-img'} src={'/dimages/trainings/authors/' + singleData.author.id + '_face.png'} />} /></Link>
                </Col>
                <Col xs={16} lg={9} className='training-author-name'>
                  <p style={{ fontWeight: 'bold' }}><Link to={'/training/author/' + singleData.author.id}>{singleData.author.name}</Link></p>
                  <p>Trenerka Strefy Cukiernika</p>
                </Col>
                <Col xs={{ span: 16, offset: 8 }} lg={{ span: 11, offset: 0 }} className='training-author-link'>
                  {singleData.author.link &&
                    <p><a
                      href={singleData.author.link}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <LinkOutlined /> {singleData.author.link_name}
                    </a></p>}
                  {singleData.author.fb &&
                    <p><a
                      href={singleData.author.fb}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FacebookOutlined /> {singleData.author.fb_name}
                    </a></p>}
                  {singleData.author.ig &&
                    <p><a
                      href={singleData.author.ig}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <InstagramOutlined /> {singleData.author.ig_name}
                    </a></p>}
                </Col>
              </Row>

              <Paragraph style={{ padding: '20px', textAlign: 'left' }} id='author'>
                {singleData.author.description}
              </Paragraph>
            </>}
          </Col>}

          {/*isMobile ? <Divider /> : ''*/}

          <Col
            xs={24}
            md={{ span: liveStatus === 'started' ? (view === 'chat' ? 16 : 12) : 10, offset: (view === 'chat' ? 4 : 0)}}
            lg={{ span: liveStatus === 'started' ? (view === 'chat' ? 16 : 11) : 6 }}
            style={{ padding: '0 20px' }}
          >
            {singleData.type === 'live' && liveStatus === 'started' && singleData.video_available && !this.props.user?.nick && <>
              <p style={{ marginTop: '20px' }}>Dołącz do czatu:</p>
              <Form
                onFinish={this.props.setUsername}
                layout='vertical'
                className={'training-form'}
                ref={this.formRef}
                initialValues={{ user_id: this.props.user?.id }}
              >
                <Form.Item
                  name='username'
                  rules={[{ required: true, message: 'Pole jest wymagane' }]}
                >
                  <Input placeholder='Twoje imię lub nick' />
                </Form.Item>

                <Form.Item className='form-footer' style={{ textAlign: 'center' }}>
                  <Button htmlType='submit' type='primary'>Dołączam</Button>
                </Form.Item>
              </Form>
            </>}

            <div
              ref={this.messagesEndRef}
              style={{
                height: isMobile ? '180px' : '400px',
                marginTop: isMobile && '20px',
                backgroundColor: '#fff',
                border: '1px solid #e5ba78',
                paddingBottom: '20px',
                borderRadius: '5px',
                overflowY: 'auto',
                display: !(singleData.type === 'live' && liveStatus === 'started' && singleData.video_available && this.props.user?.nick) && 'none'
              }}
            >
              <MessageList
                className='message-list'
                onReplyClick={this.replyToMessage}
                // onClick={this.replyToMessage}
                dataSource={this.props.messages?.map(msg => {
                  return {
                    position: msg.user_id === this.props.user?.id ? 'right' : 'left',
                    text: <>
                      {(msg.message_type === 'image' && <><Image
                        hoverable='true'
                        style={{ width: '100%', maxWidth: '100px' }}
                        src={'/dimages/chat_images/' + singleData.id + '/min-' + msg.image_filename}
                        fallback='/images/image-not-found.svg'
                        preview={{
                          src: '/dimages/chat_images/' + singleData.id + '/' + msg.image_filename,
                        }}
                      />{(msg.text && <p style={{marginBottom: 0}}>{msg.text}</p>) || ''}</>) || msg.text}

                      {msg.reactions && <div className='message-reactions' style={{
                        right: msg.user_id === this.props.user?.id ? 'auto' : '-' + (Object.keys(msg.reactions).length * 25 + 15) + 'px',
                        left: msg.user_id === this.props.user?.id ? '-' + (Object.keys(msg.reactions).length * 25 + 15) + 'px' : 'auto'
                      }}>
                        <Space>
                        {msg.reactions && Object.keys(msg.reactions).map(x => { return <Badge size="small" offset={[3, 0]} count={msg.reactions[x] > 1 ? msg.reactions[x] : undefined }><span>{x}</span></Badge> })}
                        </Space>
                      </div>}

                      <Dropdown
                        overlay={<Menu>
                          {emojis.map(e => <Menu.Item style={{ fontSize: '20px' }} onClick={() => this.props.sendReaction(msg.id, e)}>
                            {e}
                          </Menu.Item>)}
                        </Menu>}
                        placement='top'
                        className='chat-msg-reaction'
                      >
                        <Button style={{ padding: '0 6px' }}>
                          🙂
                        </Button>
                      </Dropdown>
                    </>,
                    title: msg.username,
                    replyButton: true,
                    type: 'text',
                    reply: msg.reply ? {
                      title: msg.reply.username,
                      message: msg.reply.message,
                    } : undefined
                  }
                })}
              />
              {/*<div ref={this.hiddenRef}>hidden</div>*/}
            </div>

            {liveStatus === 'started' && singleData.video_available && newMessages && <div className='scroll-to-newset'>
              <Button onClick={() => {
                this.scrollToBottom(true)
                this.setState({ newMessages: false })
              }}>
                Przewiń do najnowszych wiadomości <ArrowDownOutlined />
              </Button>
            </div>}

            {singleData.type === 'live' && liveStatus === 'started' && singleData.video_available && this.props.user?.nick && this.props.reply &&
              <Space style={{ marginTop: '20px' }}>
                <div>Odpowiadasz użytkownikowi <strong>{this.props.reply.username}</strong>{/*na wiadomość: <br />{this.props.reply.message*/}</div>
                <Button onClick={() => this.props.setReply(undefined)}>Anuluj</Button>
              </Space>}

            <Form
              onFinish={this.props.handleSubmit}
              layout='vertical'
              className='training-form'
              ref={this.props.formRef}
              style={{
                display: !(singleData.type === 'live' && liveStatus === 'started' && singleData.video_available && this.props.user?.nick) && 'none',
                justifyContent: 'center',
                marginTop: '20px'
              }}
            >
              <Form.Item name='message'>
                <Input
                  ref={this.messageRef}
                  onBlur = {(e) => {
                    this.setState({ messagePosition: e.target.selectionStart })
                  }}
                  placeholder='Napisz coś...'
                  autoComplete='off'
                />
              </Form.Item>

              <Form.Item className='form-footer'>
                <canvas
                  ref={this.pictureRef}
                  style={{ display: 'none' }}
                />
                <Space
                  style={{
                    justifyContent: 'center',
                  }}
                >
                  {!capturing && !justUploaded && <Upload
                    action={settings.api_url + `/api/chat/${singleData.id}/upload`}
                    headers={headers}
                    accept='image/png,image/jpeg'
                    beforeUpload={() => {
                      this.setState({ processingImage: true })

                      return true
                    }}
                    onChange={(x) => {
                      if(x.file.status === 'done') {
                        this.props.setJustUploaded(x?.file?.response?.filename)
                        this.setState({ processingImage: false })
                      }
                    }}
                  >
                    <Button size='large' icon={<PaperClipOutlined />} style={{ marginRight: '0' }} />
                  </Upload>}

                  {processingImage && <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin />}

                  {justUploaded && <img src={'/dimages/chat_images/' + singleData.id + '/' + justUploaded} alt='uploaded preview' style={{ maxHeight: '120px', maxWidth: '150px' }} />}
                  {justUploaded && <Button style={{ marginLeft: '10px' }} icon={<DeleteOutlined onClick={this.props.removeFile} />} />}

                  <video
                    ref={(refrence) => {
                      this.player = refrence;
                    }}
                    style={{
                      width: '200px',
                      display: 'none'
                    }}
                    autoPlay
                  ></video>

                  <Space direction={(isMobile && 'vertical') || undefined}>
                    {capturing && !processingImage && <Button size='large' type='primary' onClick={this.capturePicture} title='Zrób zdjęcie' icon={<CameraOutlined />} />}
                    {capturing && !processingImage && <Button size='large' onClick={this.switchCamera} icon={<ReloadOutlined />} title='Zmień aparat' />}
                    {capturing && !processingImage && <Button size='large' onClick={this.cancelCapturing}>Anuluj</Button>}
                  </Space>

                  {!capturing && !justUploaded && <Button size='large'
                    onClick={this.initializeMedia}
                    icon={<CameraOutlined />} style={{ marginRight: '15px' }} />}

                    <Dropdown
                      overlay={menu}
                      placement='top'
                      className='chat-reaction'
                    >
                      <Button size='large' style={{ padding: '0 10px' }}>
                        🙂
                      </Button>
                    </Dropdown>

                  {!this.props.onlyChat &&
                    <Popover content={<><p>Zeskanuj aby dołączyć z drugiego urządzenia:</p><div style={{ textAlign: 'center' }}><QRCode size={200} value={window.location.href + '?onlyChat=1'} /></div></>}>
                      <Button size='large' icon={<MobileOutlined />} style={{ marginRight: '15px' }} />
                    </Popover>}
                  {!capturing && <Button htmlType='submit' size='large' type='primary' disabled={processingImage || !chatConnected}>Wyślij</Button>}
                  {!chatConnected && 'Brak połączenia z czatem'}
                </Space>
              </Form.Item>
            </Form>

            {/*<Affix offsetTop={0} target={ isMobile ? null : undefined }>*/}
              {ctaBoxes}

              {singleData.type !== 'live' && singleData.type !== 'teaser' && <Typography style={{ padding: '0 20px 0 20px' }}>
                <Paragraph>
                  <div className='training-about'>
                    <h3>O szkoleniu</h3>
                    <ul>
                      {singleData.type === 'sub' && authenticated && user?.sub_type === 'sub1' && <li>Dostępność: {(singleData.type === 'free' && 'za darmo') || 'w pakietach ' + subName('sub2') + ', ' + subName('sub3')}</li>}
                      <li>Czas trwania: {singleData.durationNice}</li>
                      <li>Poziom: początkujący</li>
                      <li>Forma: online</li>
                      <li>Szkolenie dostępne: przez stronę www i w aplikacji mobilnej</li>
                    </ul>
                  </div>
                </Paragraph>

                {/*<Unsleep moduleName='Training Show' />*/}
              </Typography>}

              {singleData.connected_trainings?.length > 0 && <div className='connected-trainings'>
                <h3 className='training-section-title'>Szkolenia w tej serii</h3>

                {singleData.connected_trainings.map(item => <TrainingItem item={item} redirect={this.props.redirect} />)}
              </div>}

              {(singleData?.type === 'sub' && user?.sub_type === 'sub2' || singleData.private) && liveStatus !== 'started' && authenticated && singleData.certificate_issue_status && singleData.certificate_issue_status !== 'disabled' &&
                <Paragraph>
                  <h3>Certyfikat</h3>

                  {singleData.certificate_request?.status === 'toreview' &&
                  <p className='training-certificate-status'>Twoje zgłoszenie o certyfikat oczekuje na rozpatrzenie</p>}

                  {singleData.certificate_request?.status === 'rejected' &&
                  <p className='training-certificate-status'>Twoje zgłoszenie o certyfikat niestety nie zostało rozpatrzone pozytywnie... ale nie przejmuj się! Wykonaj pracę ze szkolenia ponownie i wyślij zdjęcia do oceny jeszcze raz :)</p>}

                  {singleData.certificate_request?.status === 'accepted' &&
                  <p className='training-certificate-status'>Twoje zgłoszenie o certyfikat zostało pomyślnie rozpatrzone :) :) Gratulacje!</p>}

                  {singleData.certificate_request?.response_message && <>
                    {singleData.author.diminutive || singleData.author.name} przesyła dodatkową wiadomość do Twojego zgłoszenia o certyfikat:
                    <p>{singleData.certificate_request?.response_message}</p>
                    </>}

                  {singleData.certificate_issue_status === 'not-available-yet' && <p>Zgłaszanie próśb o certyfikat jeszcze nie jest dostępne dla tego szkolenia</p>}
                  {!singleData.certificate_request && singleData.certificate_issue_status === 'finished' && <p>Wystawianie certyfikatów dla tego szkolenia zostało zakończone</p>}

                  {singleData.certificate_issue_status === 'available' && (!singleData.certificate_request || singleData.certificate_request?.status === 'rejected') &&
                  <>
                    <h4>Prześlij zdjęcie pracy wykonanej po szkoleniu</h4>
                    {/*<p>{singleData.author.diminutive || singleData.author.name} dokona oceny Twojej pracy, jeśli wszystko będzie ok otrzymasz certyfikat :)</p>*/}
                    <p>Dokonamy oceny Twojej pracy, jeśli wszystko będzie ok otrzymasz certyfikat :)</p>

                    <CertificateUploadForm
                      isMobile={isMobile}
                      training={singleData}
                      submitRequest={this.props.submitCertificateRequest}
                    />
                  </>}

                  {singleData.certificate_request?.status === 'accepted' &&
                  <Space direction='vertical'>
                    <Button
                      onClick={() => this.state.certificateText ? this.props.downloadCertificate('selfprint', this.state.certificateText) : this.showSetCertificateText(true, 'selfprint')}
                      size=''
                      className='sc-button'
                      disabled={this.props.downloadingFile}
                    >
                      <CloudDownloadOutlined /> certyfikat do samodzielnego druku {this.props.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin /></>}
                    </Button>
                    <Button
                      onClick={() => this.state.certificateText ? this.props.downloadCertificate('printinghouse', this.state.certificateText) : this.showSetCertificateText(true, 'printinghouse')}
                      size=''
                      className='sc-button'
                      disabled={this.props.downloadingFile}
                    >
                      <CloudDownloadOutlined /> certyfikat do druku w drukarni {this.props.downloadingFile && <> <LoadingOutlined style={{ fontSize: 14, marginLeft: '10px' }} spin /></>}
                    </Button>
                  </Space>}
                </Paragraph>}

            {/*</Affix>*/}
          </Col>
        </Row>
      </>
    )
  }
}

export default StyledTrainingShow
